<template>
  <div class="mt-4 flex gap-4 justify-center" v-if="filter">
    <!-- Category filter -->
    <div>
      <label for="category" class="mr-2 text-gray-700 dark:text-gray-300"
        >Categorie:</label
      >
      <select
        id="category"
        v-model="localSelectedCategory"
        @change="filterByCategory"
        class="px-4 py-2 text-gray-700 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 w-40"
      >
        <option value="">All</option>
        <option
          v-for="category in uniqueCategories"
          :value="category"
          :key="category"
        >
          {{ category }}
        </option>
      </select>
    </div>

    <!-- Recipient filter -->
    <div>
      <label for="recipient" class="mr-2 text-gray-700 dark:text-gray-300"
        >Ontvanger:</label
      >
      <select
        id="recipient"
        v-model="localSelectedRecipient"
        @change="filterByRecipient"
        class="px-4 py-2 text-gray-700 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 w-40"
      >
        <option value="">All</option>
        <option
          v-for="recipient in uniqueRecipients"
          :value="recipient"
          :key="recipient"
        >
          {{ recipient }}
        </option>
      </select>
    </div>

    <!-- Random Button -->
    <button
      class="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      @click="redirectToRandomPresent"
    >
      Random Present
    </button>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Boolean,
      default: true,
    },
    selectedCategory: {
      type: String,
      default: "",
    },
    selectedRecipient: {
      type: String,
      default: "",
    },
    uniqueCategories: {
      type: Array,
      default: () => [],
    },
    uniqueRecipients: {
      type: Array,
      default: () => [],
    },
    filterByCategory: {
      type: Function,
      default: () => {},
    },
    filterByRecipient: {
      type: Function,
      default: () => {},
    },
    redirectToRandomPresent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      localSelectedCategory: this.selectedCategory,
      localSelectedRecipient: this.selectedRecipient,
    };
  },
  watch: {
    localSelectedCategory(value) {
      this.$emit("update:selectedCategory", value);
    },
    localSelectedRecipient(value) {
      this.$emit("update:selectedRecipient", value);
    },
  },
};
</script>
