<template>
  <div class="bg-white dark:bg-gray-900 p-4 rounded-lg shadow-lg">
    <div class="flex items-center justify-center mb-4">
      <img
        :src="present.imageUrl"
        :alt="present.title"
        class="w-40 h-40 object-cover rounded-full"
      />
    </div>
    <h4 class="text-xl font-semibold text-gray-900 dark:text-gray-100">
      {{ present.title }}
    </h4>
    <p class="text-gray-700 dark:text-gray-300 mt-2">
      {{ present.shortDescription }}
    </p>
    <div class="flex justify-center mt-4">
      <router-link
        :to="{ name: 'SinglePresent', params: { id: present.id } }"
        class="inline-block px-4 py-2 text-white font-semibold bg-indigo-500 rounded-lg hover:bg-indigo-600 transition-colors duration-300 mb-2 mr-2"
      >
        Lees verder
      </router-link>

      <!-- <a
        :href="present.shopUrl"
        class="inline-block px-4 py-2 text-white font-semibold bg-indigo-500 rounded-lg hover:bg-indigo-600 transition-colors duration-300 mb-2"
      >
        Shop Now
      </a> -->
    </div>
  </div>
</template>



<script>
export default {
  name: "PresentCard",
  props: {
    present: {
      type: Object,
      required: true,
    },
  },
};
</script>
