const presents = [
  {
    id: 1,
    title: "Knuffeldoekje",
    shortDescription: "Zacht knuffeldoekje voor baby's",
    description:
      "Een zacht knuffeldoekje dat de baby troost biedt en een gevoel van veiligheid geeft.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/knuffeldoekje",
    category: "algemeen",
    recipient: "kind",
  },
  {
    id: 2,
    title: "Babysokjes",
    shortDescription: "Schattige sokjes voor pasgeboren baby's",
    description:
      "Schattige sokjes voor pasgeboren baby's om hun voetjes warm te houden.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/babysokjes",
    category: "praktisch",
    recipient: "kind",
  },
  {
    id: 3,
    title: "Babyfotoalbum",
    shortDescription: "Mooi fotoalbum voor baby's",
    description:
      "Een mooi fotoalbum om de kostbare herinneringen van de baby vast te leggen.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/babyfotoalbum",
    category: "algemeen",
    recipient: "ouders",
  },
  {
    id: 4,
    title: "Speelgoedauto",
    shortDescription: "Stoere speelgoedauto voor kinderen",
    description: "Een stoere speelgoedauto voor uren speelplezier.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/speelgoedauto",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 5,
    title: "Kraamcadeaupakket",
    shortDescription: "Samengesteld pakket met kraamcadeaus",
    description:
      "Een samengesteld pakket met verschillende kraamcadeaus voor zowel de baby als de ouders.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kraamcadeaupakket",
    category: "algemeen",
    recipient: "familie",
  },
  {
    id: 6,
    title: "Knuffelbeer",
    shortDescription: "Zachte knuffelbeer voor kinderen",
    description: "Een zachte knuffelbeer om mee te knuffelen en te troosten.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/knuffelbeer",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 7,
    title: "Babynestje",
    shortDescription: "Comfortabel nestje voor baby's",
    description:
      "Een comfortabel nestje voor de baby om in te liggen en te rusten.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/babynestje",
    category: "praktisch",
    recipient: "kind",
  },
  {
    id: 8,
    title: "Babybadset",
    shortDescription: "Complete set voor ontspannend babybadje",
    description:
      "Een complete set met alles wat nodig is voor een ontspannend babybadje.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/babybadset",
    category: "praktisch",
    recipient: "kind",
  },
  {
    id: 9,
    title: "Speelkleed",
    shortDescription: "Kleurrijk speelkleed voor baby's",
    description:
      "Een kleurrijk speelkleed waar de baby veilig op kan spelen en ontdekken.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/speelkleed",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 10,
    title: "Babyvoedingsset",
    shortDescription: "Handige set voor babyvoeding",
    description:
      "Een handige set met borden, lepels en bekers voor het voeden van de baby.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/babyvoedingsset",
    category: "praktisch",
    recipient: "kind",
  },
  {
    id: 11,
    title: "Muziekmobiel",
    shortDescription: "Muziekmobiel voor baby's",
    description:
      "Een muziekmobiel met vrolijke melodieën om de baby te kalmeren en te vermaken.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/muziekmobiel",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 12,
    title: "Kraampakket",
    shortDescription: "Essentiële producten voor kraamtijd",
    description:
      "Een pakket met essentiële producten en verzorgingsartikelen voor de eerste dagen na de geboorte.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kraampakket",
    category: "praktisch",
    recipient: "ouders",
  },
  {
    id: 13,
    title: "Kinderboek",
    shortDescription: "Leuk en leerzaam boek voor kinderen",
    description:
      "Een leuk en leerzaam boek voor kinderen om hun verbeeldingskracht te stimuleren.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kinderboek",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 14,
    title: "Knuffelkonijn",
    shortDescription: "Lief knuffelkonijn voor kinderen",
    description: "Een lief knuffelkonijn om mee te knuffelen en te spelen.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/knuffelkonijn",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 15,
    title: "Badspeelgoed",
    shortDescription: "Leuk speelgoed voor in bad",
    description:
      "Leuk speelgoed voor in bad om het badderen nog leuker te maken.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/badspeelgoed",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 16,
    title: "Houten puzzel",
    shortDescription: "Leuke houten puzzel voor kinderen",
    description:
      "Een leuke houten puzzel om de creativiteit en probleemoplossende vaardigheden van kinderen te stimuleren.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/houtenpuzzel",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 17,
    title: "Kleurboek",
    shortDescription: "Creatief kleurboek voor kinderen",
    description:
      "Een creatief kleurboek met verschillende tekeningen om in te kleuren en de verbeeldingskracht van kinderen te stimuleren.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kleurboek",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 18,
    title: "Kinderkookset",
    shortDescription: "Leuke kookset voor kinderen",
    description:
      "Een leuke kookset speciaal ontworpen voor kinderen om hun culinaire vaardigheden te ontwikkelen en te genieten van koken.",
    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kinderkookset",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 19,
    title: "Bouwstenen",
    shortDescription: "Creatieve bouwstenen voor kinderen",
    description:
      "Creatieve bouwstenen waarmee kinderen hun fantasie kunnen gebruiken en verschillende constructies kunnen bouwen.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/bouwstenen",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 20,
    title: "Tekentafel",
    shortDescription: "Handige tekentafel voor kinderen",
    description:
      "Een handige tekentafel met een whiteboard-oppervlak en opbergruimte voor tekenspullen, ideaal voor kleine kunstenaars.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/tekentafel",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 21,
    title: "Kindercamera",
    shortDescription: "Leuke camera voor kinderen",
    description:
      "Een leuke en veilige camera speciaal ontworpen voor kinderen om foto's te maken en hun creativiteit vast te leggen.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kindercamera",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 22,
    title: "Kinderkoptelefoon",
    shortDescription: "Veilige koptelefoon voor kinderen",
    description:
      "Een veilige en comfortabele koptelefoon speciaal ontworpen voor kinderen om naar muziek te luisteren en filmpjes te kijken.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kinderkoptelefoon",
    category: "elektronica",
    recipient: "kind",
  },
  {
    id: 23,
    title: "Schaakspel",
    shortDescription: "Leerzaam schaakspel voor kinderen",
    description:
      "Een leerzaam schaakspel waarmee kinderen strategisch denken en probleemoplossende vaardigheden kunnen ontwikkelen.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/schaakspel",
    category: "speelgoed",
    recipient: "kind",
  },
  {
    id: 24,
    title: "Kinderhorloge",
    shortDescription: "Stijlvol horloge voor kinderen",
    description:
      "Een stijlvol horloge speciaal ontworpen voor kinderen om de tijd te leren en bij te blijven met stijl.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/kinderhorloge",
    category: "mode",
    recipient: "kind",
  },
  {
    id: 25,
    title: "Knutselset",
    shortDescription: "Creatieve knutselset voor kinderen",
    description:
      "Een creatieve knutselset met verschillende materialen en ideeën om kinderen te inspireren en hun artistieke vaardigheden te stimuleren.",

    imageUrl: require("@/assets/images/presents/baby.jpg"),
    shopUrl: "/shop/knutselset",
    category: "speelgoed",
    recipient: "kind",
  },
];
export default presents;
