<template>
  <div class="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-2">
    <!-- Render present cards -->
    <PresentCard
      v-for="present in paginatedPresents"
      :key="present.id"
      :present="present"
    />
  </div>
</template>

<script>
import PresentCard from "./PresentCard.vue";

export default {
  components: {
    PresentCard,
  },
  props: {
    paginatedPresents: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
