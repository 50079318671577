<script>
// import AppBanner from '@/components/shared/AppBanner';
// import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
import PresentGrid from "@/components/presentTool/PresentsGrid.vue";
import TopicSection from "@/components/topics/TopicSection.vue";
// import Button from '../components/reusable/Button.vue';

export default {
  name: "Home",
  components: {
    // AppBanner,
    // ProjectsGrid,
    PresentGrid,
    TopicSection,
    // Button,
  },
};
</script>

<template>
  <div class="container mx-auto">
    <!-- Banner -->
    <!-- <AppBanner class="mb-5 sm:mb-8" /> -->

    <!-- Projects -->
    <!-- <ProjectsGrid /> -->

    <!-- Load more projects button -->
    <!-- <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
        to="/projects"
        class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
        aria-label="More Projects"
      >
        <Button title="More Projects" />
      </router-link>
    </div> -->
    <section class="text-center px-8 mt-20 mb-10 sm:mt-32 md:mt-40">
      <h2
        class="text-slate-900 text-4xl tracking-tight font-extrabold sm:text-5xl dark:text-white"
      >
        "Een goed geschreven woord veroudert niet."
      </h2>
      <figure>
        <blockquote>
          <p class="mt-6 max-w-3xl mx-auto text-lg dark:text-white">
            Geluk voor Baby zorgt dat men mee gaat met de tijd. Met dit online
            gastenboek/dagboek kunnen naasten een berichtje schrijven voor
            degene die zwanger is. Op deze manier kan iedereen schrijven wat
            hij/zij denkt op dat moment en kunnen de ouders dit op hun moment
            nalezen. Benieuwd naar het hele concept?
            <a class="underline" href="/explanation">lees verder</a>
          </p>
        </blockquote>
      </figure>
    </section>

    <section class="bg-gray-100 dark:bg-gray-800 py-16">
      <div class="container mx-auto">
        <h3
          class="text-3xl font-semibold text-gray-900 dark:text-gray-100 mb-8"
        >
          Cadeautjes voor baby's en hun ouders
        </h3>
        <p class="text-lg text-gray-700 dark:text-gray-300">
          Onze tool is speciaal ontworpen om je te helpen bij het verzinnen van
          het ideale cadeau voor baby's en hun ouders. Of je nu op zoek bent
          naar iets praktisch, creatiefs of unieks, onze tool biedt een scala
          aan inspirerende ideeën die aansluiten bij verschillende interesses en
          behoeften. Of het nu gaat om handige babyproducten, gepersonaliseerde
          geschenken of iets dat zowel ouders als baby's zal verrassen, onze
          tool geeft je talloze suggesties om uit te kiezen. We begrijpen dat
          elk cadeau speciaal moet zijn, en daarom streven we ernaar om je te
          voorzien van opties die zowel praktisch als betekenisvol zijn, zodat
          je een cadeau kunt geven dat lang gekoesterd zal worden.
        </p>
        <p class="text-lg text-gray-700 dark:text-gray-300 mt-4">
          Met onze tool hoef je je geen zorgen meer te maken over het vinden van
          het juiste cadeau voor baby's. Laat ons je helpen bij het bedenken van
          een geschenk dat zowel ouders als baby's zal verwennen en waar ze echt
          van zullen genieten.
        </p>
        <div class="mt-8">
          <router-link
            :to="{ path: '/presentTool', name: 'PresentTool' }"
            class="inline-block px-6 py-3 text-white font-semibold bg-indigo-500 rounded-lg hover:bg-indigo-600 transition-colors duration-300"
          >
            Ga naar de cadeau tool
          </router-link>
        </div>

        <PresentGrid :displayLimit="2" :filter="false" />
      </div>
    </section>

    <!-- New Section: Topics -->
    <TopicSection/>
  </div>
</template>


<style scoped></style>
