<script>
import feather from "feather-icons";
import Button from "./reusable/Button.vue";
import FormInput from "./reusable/FormInput.vue";
// import FormTextarea from './reusable/FormTextarea.vue';
// import axios from 'axios';

export default {
  props: ["showModal", "modal", "categories"],
  components: {
    Button,
    FormInput,
    // FormTextarea
  },
  data() {
    return {
      status: "creating", ///creating, sending, done
      message: "Aan het verzenden",
      formData: {
        firstname_creator: "",
        lastname_creator: "",
        creator_email: "",
        firstname_mother: "",
        lastname_mother: "",
        email_mother: "",
        firstname_father: "",
        lastname_father: "",
        email_father: "",
      },
      responseData: {},
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
  methods: {
    handleInput(fieldName, value) {
      this.formData[fieldName] = value;
    },
    async sendEmail(nameSender, emailSender, message, emailReceiver) {
      try {
        const url = "https://www.halloween-schoenmaker.nl/send_mail.php";
        const data = new URLSearchParams({
          name_sender: nameSender,
          email_sender: emailSender,
          message: message,
          email_receiver: emailReceiver,
        });

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: data,
        });

        if (response.ok) {
          const responseText = await response.text();
          console.log(responseText); // E-mail succesfully sent!
        } else {
          throw new Error("Error sending email. Please try again.");
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async submitForm() {
      this.status = "sending";
      // Prepare the form data for submission
      const params = new URLSearchParams();
      Object.entries(this.formData).forEach(([key, value]) => {
        params.append(key, value);
      });

      try {
        // Send the POST request
        const response = await fetch(
          "https://www.halloween-schoenmaker.nl/create_couple.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: params,
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.error) {
            // Handle error response
            console.error(data.error);
          } else {
            // Handle success response
            this.responseData = data;
            console.log(data.message);
            console.log("Generated Password: " + data.generatedPassword);
            console.log("Newly Created ID: " + data.newlyCreatedId);
          }
        } else {
          throw new Error("An error occurred during form submission.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }

      this.status = "done"; ///TO-DO create right error handling
    },
    async createCouple() {
      await this.submitForm();

      const link =
        "www.gelukvoorbaby.nl/message/" + this.responseData.newlyCreatedId;
      const emailContent = `Bedankt voor het aanmaken van een niew koppel. Klik <a href="${link}">hier</a> om een berichtje achter te laten. Bewaar deze link goed, want dit is de enige manier om een berichtje te plaatsen. Vervolgens is het mogelijk om de link te delen met anderen, zodat zij ook een berichtje kunnen plaatsen.<br>`;

      await this.sendEmail(
        "Geluk voor Baby",
        "info@gelukvoorbaby.nl",
        emailContent,
        this.formData.creator_email
      );
    },
  },
};
</script>
<template>
  <transition name="fade">
    <div v-show="modal" class="font-general-regular fixed inset-0 z-30">
      <!-- Modal body background as backdrop -->
      <div
        v-show="modal"
        @click="showModal()"
        class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"
      ></div>
      <!-- Modal content -->
      <main class="flex flex-col items-center justify-center h-full w-full">
        <transition name="fade-up-down">
          <div v-show="modal" class="modal-wrapper flex items-center z-30">
            <div
              class="modal max-w-md mx-5 md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative"
            >
              <div
                class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark"
              >
                <h5 class="text-primary-dark dark:text-primary-light text-xl">
                  Registreren van nieuw stel
                </h5>
                <button
                  class="px-4 text-primary-dark dark:text-primary-light"
                  @click="showModal()"
                >
                  <i data-feather="x"></i>
                </button>
              </div>
              <div class="modal-body p-5 w-full h-full overflow-y-auto">
                <form
                  class="max-w-xl m-4 text-left"
                  @submit.prevent="createCouple()"
                  v-if="status === 'creating'"
                >
                  <!-- Creator -->
                  <FormInput
                    label="Voornaam aanmaker"
                    inputIdentifier="firstname_creator"
                    class="mb-2"
                    @input="formData.firstname_creator = $event.target.value"
                  />
                  <FormInput
                    label="Achternaam aanmaker"
                    inputIdentifier="lastname_creator"
                    class="mb-2"
                    @input="formData.lastname_creator = $event.target.value"
                  />
                  <FormInput
                    label="E-mail aanmaker"
                    inputIdentifier="creator_email"
                    inputType="email"
                    @input="formData.creator_email = $event.target.value"
                  />

                  <!-- Mother -->
                  <FormInput
                    label="Voornaam aanstaande moeder"
                    inputIdentifier="firstname_mother"
                    class="mb-2"
                    @input="formData.firstname_mother = $event.target.value"
                  />
                  <FormInput
                    label="Achternaam aanstaande moeder"
                    inputIdentifier="lastname_mother"
                    class="mb-2"
                    @input="formData.lastname_mother = $event.target.value"
                  />
                  <FormInput
                    label="E-mail aanstaande moeder"
                    inputIdentifier="email_mother"
                    inputType="email"
                    @input="formData.email_mother = $event.target.value"
                  />

                  <!-- Father -->
                  <FormInput
                    label="Voornaam aanstaande vader"
                    inputIdentifier="firstname_father"
                    class="mb-2"
                    @input="formData.firstname_father = $event.target.value"
                  />
                  <FormInput
                    label="Achternaam aanstaande vader"
                    inputIdentifier="lastname_father"
                    class="mb-2"
                    @input="formData.lastname_father = $event.target.value"
                  />
                  <FormInput
                    label="E-mail aanstaande vader"
                    inputIdentifier="email_father"
                    inputType="email"
                    @input="formData.email_father = $event.target.value"
                  />
                </form>
                <p
                  class="max-w-3xl mx-auto text-lg dark:text-white"
                  v-if="status === 'sending'"
                >
                  {{ this.message }}
                </p>
                <p
                  class="max-w-3xl mx-auto text-lg dark:text-white"
                  v-if="status === 'done'"
                >
                  Het nieuwe stel is succesvol aangemaakt
                  <router-link
                    class="underline"
                    :to="'/message/' + responseData.newlyCreatedId"
                    @click="showModal()"
                    >schrijf hier het eerste berichtje</router-link
                  >.
                </p>
              </div>
              <div
                class="modal-footer flex space-between mt-2 sm:mt-0 py-5 px-8 border0-t text-right"
              >
                <Button
                  title="Close"
                  class="px-4 h-fit sm:px-6 py-2 sm:py-2.5 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200 dark:text-secondary-dark dark:hover:bg-primary-light rounded-md focus:ring-1 focus:ring-indigo-900 duration-500"
                  @click="showModal()"
                  aria-label="Close Modal"
                />
                <div class="grow"></div>
                <Button
                  title="Maak nieuw stel aan"
                  class="px-4 h-fit sm:px-6 py-2 sm:py-2.5 text-white bg-indigo-500 hover:bg-indigo-600 rounded-md focus:ring-1 focus:ring-indigo-900 duration-500"
                  @click="submitForm()"
                  aria-label="Maak nieuw stel aan"
                />
              </div>
            </div>
          </div>
        </transition>
      </main>
    </div>
  </transition>
</template>

<style scoped>
.modal-body {
  max-height: 570px;
}
.bg-gray-800-opacity {
  background-color: #2d374850;
}
@media screen and (max-width: 768px) {
  .modal-body {
    max-height: 400px;
  }
}
.fade-up-down-enter-active {
  transition: all 0.3s ease;
}
.fade-up-down-leave-active {
  transition: all 0.3s ease;
}
.fade-up-down-enter {
  transform: translateY(10%);
  opacity: 0;
}
.fade-up-down-leave-to {
  transform: translateY(10%);
  opacity: 0;
}

.fade-enter-active {
  -webkit-transition: opacity 2s;
  transition: opacity 0.3s;
}
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
