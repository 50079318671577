<template>
  <div>
    <present-filters
      :filter="filter"
      :selected-category="selectedCategory"
      :selected-recipient="selectedRecipient"
      :unique-categories="uniqueCategories"
      :unique-recipients="uniqueRecipients"
      :filter-by-category="filterByCategory"
      :filter-by-recipient="filterByRecipient"
      :redirect-to-random-present="redirectToRandomPresent"
      @update:selected-category="updateSelectedCategory"
      @update:selected-recipient="updateSelectedRecipient"
    ></present-filters>

    <present-card-grid :paginated-presents="paginatedPresents" />

    <pagination
      :current-page="currentPage"
      :page-count="pageCount"
      @change-page="changePage"
      v-if="pageCount > 1"
    ></pagination>
  </div>
</template>

<script>
import PresentFilters from "./PresentFilters.vue";
import PresentCardGrid from "./PresentCardGrid.vue";
import Pagination from "./Pagination.vue";
import presentsData from "@/data/presents.js";

export default {
  components: {
    PresentFilters,
    PresentCardGrid,
    Pagination,
  },
  props: {
    displayLimit: {
      type: Number,
      default: Infinity,
    },
    filter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      presents: presentsData,
      selectedCategory: "",
      selectedRecipient: "",
      currentPage: 1,
      pageSize: 6, // Number of presents per page
    };
  },
  computed: {
    filteredPresents() {
      return this.presents.filter((present) => {
        const categoryMatch = this.selectedCategory
          ? present.category === this.selectedCategory
          : true;
        const recipientMatch = this.selectedRecipient
          ? present.recipient === this.selectedRecipient
          : true;
        return categoryMatch && recipientMatch;
      });
    },
    uniqueCategories() {
      return Array.from(
        new Set(this.presents.map((present) => present.category))
      );
    },
    uniqueRecipients() {
      return Array.from(
        new Set(this.presents.map((present) => present.recipient))
      );
    },
    limitedFilteredPresents() {
      return this.filteredPresents.slice(0, this.displayLimit);
    },
    paginatedPresents() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.limitedFilteredPresents.slice(startIndex, endIndex);
    },
    pageCount() {
      return Math.ceil(this.limitedFilteredPresents.length / this.pageSize);
    },
  },
  methods: {
    filterByCategory() {
      this.selectedRecipient = "";
    },
    filterByRecipient() {
      this.selectedCategory = "";
    },
    redirectToRandomPresent() {
      const randomIndex = Math.floor(
        Math.random() * this.filteredPresents.length
      );
      const randomPresent = this.filteredPresents[randomIndex];

      // Redirect to the detail page of the random present
      this.$router.push({
        name: "SinglePresent",
        params: { id: randomPresent.id },
      });
    },
    changePage(page) {
      this.currentPage = page;
      window.scrollTo(0, 0); // Scroll to the top of the page when changing the page
    },
    updateSelectedCategory(value) {
      this.selectedCategory = value;
    },
    updateSelectedRecipient(value) {
      this.selectedRecipient = value;
    },
  },
};
</script>
