<template>
  <div class="mt-4 flex justify-center">
    <button
      v-if="currentPage > 1"
      @click="changePage(currentPage - 1)"
      class="inline-block font-bold py-2 px-4 rounded-lg mr-2"
    >
      Previous
    </button>

    <!-- <span v-if="currentPage > delta" class="inline-block font-bold py-2 px-4 rounded-lg mr-2">...</span> -->

    <button
      v-for="page in pages"
      :key="page"
      @click="changePage(page)"
      :class="{
        'bg-blue-500 hover:bg-blue-600': currentPage !== page,
        'bg-blue-600': currentPage === page,
        'text-white': currentPage !== page,
      }"
      class="inline-block font-bold py-2 px-4 rounded-lg mr-2"
    >
      {{ page }}
    </button>

    <span
      v-if="pages[pages.length - 1] < pageCount"
      class="inline-block font-bold py-2 px-4 rounded-lg mr-2"
      >...</span
    >

    <button
      v-if="currentPage < pageCount"
      @click="changePage(currentPage + 1)"
      class="inline-block font-bold py-2 px-4 rounded-lg mr-2"
    >
      Next
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      // pageCount: 10, // Change this to your desired page count
      delta: 0,
      pages: [],
    };
  },
  props: {
    // currentPage: {
    //   type: Number,
    //   default: 1,
    // },
    pageCount: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    currentPage(newValue) {
      // Handle changes to the currentPage prop
      console.log("currentPage changed to:", newValue);
    },
    pageCount(newValue) {
      // Handle changes to the pageCount prop
      console.log("pageCount changed to:", newValue);
    },
  },
  mounted() {
    this.calculatePagination();
  },
  methods: {
    changePage(page) {
      // Emit an event or perform the necessary logic to handle page change
      console.log("Changing to page", page);
      this.$emit("change-page", page)
      this.currentPage = page;
      this.calculatePagination();
    },
    calculatePagination() {
      let delta;
      if (this.pageCount <= 7) {
        delta = 7;
      } else {
        delta =
          this.currentPage > 4 && this.currentPage < this.pageCount - 3 ? 2 : 4;
      }

      const range = {
        start: Math.round(this.currentPage - delta / 2),
        end: Math.round(this.currentPage + delta / 2),
      };

      if (range.start - 1 === 1 || range.end + 1 === this.pageCount) {
        range.start += 1;
        range.end += 1;
      }

      let pages =
        this.currentPage > delta
          ? this.getRange(
              Math.min(range.start, this.pageCount - delta),
              Math.min(range.end, this.pageCount)
            )
          : this.getRange(1, Math.min(this.pageCount, delta + 1));

      const withDots = (value, pair) =>
        pages.length + 1 !== this.pageCount ? pair : [value];

      if (pages[0] !== 1) {
        pages = withDots(1, [1, "..."]).concat(pages);
      }

      if (pages[pages.length - 1] < this.pageCount) {
        pages = pages.concat(withDots(this.pageCount, ["...", this.pageCount]));
      }

      this.pages = pages;
      this.delta = delta;
    },
    getRange(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((v, i) => i + start);
    },
  },
};
</script>

<style>
button:focus {
  outline: none;
}
</style>
