<template>
  <section class="py-16">
    <div class="container mx-auto">
      <h3 class="text-3xl font-semibold text-gray-900 mb-8">Topics</h3>

      <!-- GridView -->
      <div class="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Card 1 -->
        <router-link
          v-for="(topic, index) in topics"
          :key="index"
          :to="'/singleTopic/' + topic.id"
          class="shadow-lg rounded p-8 lg:p-4"
        >
          <img
            :src="topic.image"
            :alt="topic.title + ' Image'"
            class="w-full h-auto mx-auto mb-4 lg:max-w-xs"
          />
          <h3 class="text-center text-lg font-semibold text-blue-600 truncate">
            {{ topic.title }}
          </h3>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { topics } from "@/data/topics.js";

export default {
  name: "TopicsPage",
  beforeMount(){
    console.log(topics)
  },
  data() {
    return {
      topics: topics,
    };
  },
};
</script>
