export const topics = [
  {
    id: 1,
    title: "Prenatale Zorg: Het Belang van Zorg tijdens de Zwangerschap",
    introduction:
      "Prenatale zorg, ook wel bekend als zorg tijdens de zwangerschap, is van cruciaal belang voor de gezondheid van zowel de moeder als de ongeboren baby. Het omvat medische controles, gezondheidsadvies en educatie die gericht zijn op het bevorderen van een gezonde zwangerschap en het minimaliseren van risico's. In dit artikel zullen we de verschillende aspecten van prenatale zorg verkennen en het belang ervan benadrukken voor een succesvolle zwangerschap.",
    topic: "Prenatale Zorg",
    importance:
      "Prenatale zorg is essentieel omdat het de mogelijkheid biedt om de gezondheidstoestand van de moeder en de groei van de baby op te volgen. Regelmatige bezoeken aan de zorgverlener stellen hen in staat om potentiële problemen vroegtijdig te detecteren en passende maatregelen te nemen. Dit kan complicaties verminderen en de kans op een gezonde zwangerschap vergroten.",
    tip: {
      content1:
        "Een belangrijke tip voor prenatale zorg is om een uitgebalanceerd dieet te volgen dat rijk is aan voedingsstoffen zoals foliumzuur, ijzer en calcium. Deze voedingsstoffen zijn essentieel voor de gezonde ontwikkeling van de baby.",
      content2:
        "Daarnaast is het belangrijk om voldoende rust te nemen en stress te verminderen. Dit kan bijdragen aan het welzijn van zowel de moeder als de baby tijdens de zwangerschap.",
      link: "https://gelukvoorbaby.nl/singletopic/2", //topic about food
      linkText: "Lees meer over gezonde voeding tijdens de zwangerschap",
    },
    roleOfLovedOnes: [
      "Partners kunnen een ondersteunende rol spelen door betrokken te zijn bij prenatale afspraken en te helpen bij het creëren van een gezonde en rustige omgeving voor de zwangere vrouw.",
      "Familieleden en vrienden kunnen emotionele steun bieden tijdens de zwangerschap en praktische hulp bieden wanneer dat nodig is.",
      "Zorgverleners, zoals verloskundigen en gynaecologen, spelen een cruciale rol bij het verstrekken van deskundige zorg en begeleiding gedurende de zwangerschap.",
    ],
    conclusion:
      "Prenatale zorg is van onschatbare waarde voor een gezonde zwangerschap en de geboorte van een gezonde baby. Het biedt medische controles, gezondheidsadvies, educatie en emotionele ondersteuning aan aanstaande moeders. Door prenatale zorg serieus te nemen en actief deel te nemen aan het zorgproces, kunnen vrouwen de gezondheid van zichzelf en hun baby bevorderen en potentiële risico's minimaliseren. Laten we ons bewust zijn van het belang van prenatale zorg en streven naar een positieve en gezonde zwangerschapservaring voor elke moeder.",
    image: require("@/assets/images/topics/belly.jpg"),
  },
  {
    id: 2,
    title:
      "Gezonde Voeding tijdens de Zwangerschap: Het Belang van Goede Eetgewoonten",
    introduction:
      "Tijdens de zwangerschap is het van groot belang om aandacht te besteden aan een gezond voedingspatroon. Wat een vrouw eet en drinkt heeft namelijk invloed op de gezondheid en ontwikkeling van zowel de moeder als de baby. In dit artikel zullen we de essentiële voedingsstoffen bespreken die nodig zijn tijdens de zwangerschap, evenals de voordelen van gezonde eetgewoonten en tips om een uitgebalanceerd dieet te volgen.",
    topic: "Gezonde Voeding tijdens de Zwangerschap",
    importance:
      "Gezonde voeding tijdens de zwangerschap speelt een cruciale rol bij het ondersteunen van de groei en ontwikkeling van de baby. Het voorziet de baby van de juiste voedingsstoffen om gezond te groeien en vermindert het risico op complicaties zoals vroeggeboorte, laag geboortegewicht en aangeboren afwijkingen. Bovendien helpt een gezond voedingspatroon de moeder om energie op te bouwen, haar immuunsysteem te versterken en haar eigen gezondheid te bevorderen.",
    tip: {
      content1:
        "Een belangrijke tip voor gezonde voeding tijdens de zwangerschap is om gevarieerd en evenwichtig te eten. Dit betekent het opnemen van verschillende voedselgroepen, zoals volle granen, groenten, fruit, magere eiwitten en gezonde vetten.",
      content2:
        "Daarnaast is het belangrijk om voldoende water te drinken om gehydrateerd te blijven en cafeïne en alcohol te vermijden, omdat deze stoffen schadelijk kunnen zijn voor de baby.",
      link: "https://www.thuisarts.nl/zwanger/ik-ben-zwanger-en-wil-gezond-eten",
      linkText: "Lees meer over gezonde voeding tijdens de zwangerschap",
    },
    roleOfLovedOnes: [
      "Partners kunnen een ondersteunende rol spelen bij het ondersteunen van gezonde eetgewoonten. Samen gezonde maaltijden bereiden en gezonde snacks kiezen kan de motivatie vergroten en een positieve invloed hebben op het dieet van de zwangere vrouw.",
      "Familieleden en vrienden kunnen ook helpen door gezonde voedingskeuzes aan te moedigen en te zorgen voor een ondersteunende omgeving waarin gezonde eetgewoonten worden gestimuleerd.",
      "Zorgverleners, zoals verloskundigen en voedingsdeskundigen, spelen een belangrijke rol bij het verstrekken van advies en begeleiding over gezonde voeding tijdens de zwangerschap.",
    ],
    conclusion:
      "Het volgen van een gezond voedingspatroon tijdens de zwangerschap is van essentieel belang voor zowel de moeder als de baby. Door te zorgen voor een gevarieerd en uitgebalanceerd dieet, kunnen vrouwen ervoor zorgen dat ze de juiste voedingsstoffen binnenkrijgen om de gezondheid en ontwikkeling van hun baby te ondersteunen. Het betrekken van de partner, familieleden en zorgverleners kan helpen bij het creëren van een ondersteunende omgeving en het naleven van gezonde eetgewoonten. Met de juiste kennis en toewijding kan een zwangere vrouw een gezond voedingspatroon handhaven en zo bijdragen aan een gezonde zwangerschap en de geboorte van een gezonde baby.",
    image: require("@/assets/images/topics/belly.jpg"),
  },
  {
    id: 3,
    title:
      "Lichamelijke Activiteit en Rust tijdens Zwangerschap: Het Belang van een Gebalanceerde Benadering",
    introduction:
      "Tijdens de zwangerschap is het belangrijk om aandacht te besteden aan zowel lichamelijke activiteit als rust. Een gebalanceerde benadering van beide kan bijdragen aan het behoud van een goede gezondheid voor zowel de moeder als de baby. In dit artikel zullen we bespreken waarom lichamelijke activiteit en rust belangrijk zijn tijdens de zwangerschap, de voordelen van een actieve levensstijl en het belang van voldoende rust en slaap voor een gezonde zwangerschap.",
    topic: "Lichamelijke Activiteit en Rust tijdens Zwangerschap",
    importance:
      "Zowel lichamelijke activiteit als rust spelen een cruciale rol bij het bevorderen van een gezonde zwangerschap. Lichamelijke activiteit kan helpen bij het behoud van een gezond gewicht, het verbeteren van de stemming en het verminderen van zwangerschapskwaaltjes. Het kan ook de kracht en uithoudingsvermogen opbouwen, wat van pas kan komen tijdens de bevalling. Aan de andere kant is voldoende rust en slaap essentieel voor het herstel van het lichaam, het bevorderen van een gezonde groei van de baby en het verminderen van vermoeidheid en stress.",
    tip: {
      content1:
        "Een belangrijke tip voor lichamelijke activiteit tijdens de zwangerschap is om te kiezen voor veilige en geschikte activiteiten, zoals wandelen, zwemmen, yoga en prenatale oefeningen. Het is belangrijk om naar het lichaam te luisteren en activiteiten aan te passen aan de veranderende behoeften en capaciteiten tijdens de zwangerschap.",
      content2:
        "Wat betreft rust en slaap, is het belangrijk om voldoende tijd te nemen om te ontspannen en te rusten. Dit kan onder andere worden bereikt door het creëren van een comfortabele slaapomgeving, het volgen van een regelmatig slaapschema en het vermijden van stimulerende activiteiten vlak voor het slapengaan.",
      link: "https://www.allesoversport.nl/thema/gezonde-leefstijl/een-fittere-zwangerschap-door-sport-en-bewegen/",
      linkText:
        "Lees meer over lichamelijke activiteit en rust tijdens de zwangerschap",
    },
    roleOfLovedOnes: [
      "Partners kunnen een ondersteunende rol spelen bij het aanmoedigen van lichamelijke activiteit en het creëren van een rustgevende omgeving. Samen wandelen of deelnemen aan prenatale lessen kan zowel lichamelijke activiteit als een band tussen de partners bevorderen.",
      "Familieleden en vrienden kunnen ook helpen door te zorgen voor voldoende rusttijd voor de zwangere vrouw, het aanbieden van hulp bij huishoudelijke taken en het creëren van een stressvrije omgeving.",
      "Zorgverleners, zoals verloskundigen en gezondheidsdeskundigen, kunnen advies en begeleiding bieden met betrekking tot veilige lichamelijke activiteiten en het bevorderen van voldoende rust en slaap tijdens de zwangerschap.",
    ],
    conclusion:
      "Een gebalanceerde benadering van lichamelijke activiteit en rust tijdens de zwangerschap is van groot belang voor zowel de moeder als de baby. Door veilige en geschikte activiteiten te kiezen en voldoende rust en slaap te krijgen, kunnen zwangere vrouwen bijdragen aan een gezonde zwangerschap en het welzijn van zichzelf en hun baby. Het betrekken van partners, familieleden en zorgverleners kan helpen bij het creëren van een ondersteunende omgeving en het naleven van een gebalanceerde benadering van lichamelijke activiteit en rust.",
    image: require("@/assets/images/topics/belly.jpg"),
  },
  {
    id: 4,
    title: "Emotionele Steun tijdens de Zwangerschap",
    introduction: "Tijdens de zwangerschap ervaren vrouwen vaak een scala aan emoties en veranderingen. Het is een periode van fysieke, mentale en emotionele transformatie. Naast medische zorg is ook emotionele steun van groot belang. In dit artikel bespreken we de betekenis van emotionele steun tijdens de zwangerschap, waarom het zo belangrijk is en hoe naasten hierbij een waardevolle rol kunnen spelen.",
    topic: "Emotionele Steun",
    importance: "Belang van emotionele steun tijdens de zwangerschap",
    tip: {
      content1: "Zorg voor open communicatie",
      content2: "Toon begrip en empathie",
      link: "https://example.com/tip1",
      linkText: "Lees meer over het bieden van emotionele steun",
    },
    roleOfLovedOnes: [
      "Bied luisterend oor",
      "Wees aanwezig en ondersteunend",
      "Help bij het creëren van een rustige en positieve omgeving",
    ],
    conclusion: "Emotionele steun speelt een cruciale rol tijdens de zwangerschap. Het kan de moeder helpen om zich gesteund en begrepen te voelen, stress te verminderen en haar welzijn te bevorderen. Naasten hebben de mogelijkheid om een positieve impact te hebben door aanwezig te zijn, te luisteren en empathie te tonen. Door emotionele steun te bieden, kunnen geliefden een waardevolle bijdrage leveren aan het welzijn van de zwangere vrouw.",
    image: require("@/assets/images/presents/baby.jpg"),
  }
];

export default topics;
